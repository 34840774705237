<template>
  <div class="videoTitle">AI自动识别人脸，无需点击按钮</div>
  <div class="user-icon">
    <video
      width="550"
      height="440"
      ref="videoDom"
      id="video"
      preload
      autoplay
      loop
      muted
    ></video>
    <canvas width="550" height="440" ref="canvasDOM"></canvas>
    <div class="videoTips">
      {{
        isTracking
          ? "已检测到人脸，请保持人脸在中央不动，倒计时" + TrackingTime
          : "请您保持脸部在画面中央（环境光线强弱，反光等原因均有可能识别不到人脸）"
      }}
    </div>
  </div>
</template>

<script>
// 嘴巴等特征,后期可添加
// require('tracking/build/data/mouth-min.js')
// require('tracking/build/data/eye-min.js')
//var objects = new tracking.ObjectTracker(['face', 'eye', 'mouth']);
// require('tracking/examples/assets/stats.min.js')
let TrackingInterval;
export default {
  name: "VideoCanvas",
  props: {
    mediaConstraints: {
      type: Object,
      default: {
        video: true,
        audio: false,
      },
    },
  },
  data() {
    return {
      // 记录拍照到了几次
      isTracking: false,
      TrackingTime: 3,
      count: 0,
      isdetected:
        "请您保持脸部在画面中央（环境光线强弱，反光等原因均有可能识别不到人脸）",
      loding: "",
    };
  },
  unmounted() {
    this.onStopTracking();
  },
  mounted() {
    this.video = this.$refs.videoDom;
    this.canvas = this.$refs.canvasDOM;
    //初始化获取tonken
    setTimeout(() => {
      this.initTracker();
    }, 500);
    window.onunload = function () {
      this.TrackingTime = -1;
    };
  },
  methods: {
    // 在创建企业返回
    onBack() {
      this.getDataList(this.searchText);
      this.isShowDrawer = false;
    },
    // 初始化racker
    initTracker() {
      // 启用摄像头,这一个是原生调用摄像头的功能,不写的话有时候谷歌浏览器调用摄像头会失败
      navigator.mediaDevices
        .getUserMedia(this.mediaConstraints)
        .then(this.getMediaStreamSuccess)
        .catch(this.getMediaStreamError);

      this.context = this.canvas.getContext("2d");
      this.context.fillStyle = "#fff";

      // 初始化tracking参数
      this.tracker = new tracking.ObjectTracker("face");
      this.tracker.setInitialScale(4);
      this.tracker.setStepSize(2);
      this.tracker.setEdgesDensity(0.1);
      this.tracker.on("track", (event) => {
        // alert('成功')
        this.onTracked(event);
      });

      // tracking启用摄像头,这里我选择调用原生的摄像头
      // tracking.track(this.video, this.tracker, { camera: true });

      // 如果是读取视频，可以用trackerTask.stop trackerTask.run来暂停、开始视频
      this.trackerTask = tracking.track(this.video, this.tracker);
    },
    // 监听中
    onTracked(event) {
      // 判断终止条件, stop是异步的，不返回的话，还会一直截图
      // if (this.count >= 21) {
      //   this.onStopTracking();
      //   return;
      // }
      // 画框框
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      event.data.forEach((rect) => {
        // this.context.lineWidth = 1;
        // this.context.strokeStyle = "#a64ceb";
        //'#a64ceb';
        // this.context.strokeRect(rect.x, rect.y, rect.width, rect.height);
        // this.context.font = "11px Helvetica";
        // this.context.fillStyle = "#fff";
        const numberString = 10;
        // 截图
        if (event.data.length > 0 && this.count <= numberString) {
          if (this.count < 0) {
            this.count = 0;
          }
          this.count += 1;
          if (this.count > numberString) {
            this.isdetected = "已检测到人脸，正在识别";
            if (!this.isTracking) {
              this.setTime();
            }
            // this.getPhoto();
          }
        } else {
          this.count -= 1;
          if (this.count < 0) {
            this.isdetected =
              "请您保持脸部在画面中央（环境光线强弱，反光等原因均有可能识别不到人脸）";
          }
        }
      });
      // 视频中心展示文字
      // this.context.fillText(this.isdetected, 100, 30);
    },
    setTime() {
      this.isTracking = true;
      this.TrackingTime = 3;
      if (TrackingInterval) {
        clearInterval(TrackingInterval);
      }
      TrackingInterval = setInterval(() => {
        if (this.TrackingTime === 0) {
          clearInterval(TrackingInterval);
          setTimeout(() => {
            this.count = 0;
            this.getPhoto();
            this.isTracking = false;
            this.onStopTracking();
          }, 500);
        } else {
          this.TrackingTime--;
        }
      }, 1000);
    },
    // 停止监听
    onStopTracking() {
      if (this.trackerTask) {
        this.trackerTask.stop();
      }
      this.video.pause();
      // 关闭摄像头
      this.video.srcObject = null;
      // window.stream.getTracks().forEach((track) => track.stop());
    },
    // 获取人脸照片
    getPhoto() {
      this.isdetected = "";
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      let video = document.getElementById("video");
      this.context.drawImage(
        video,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );
      let dataUrl = this.canvas.toDataURL("image/jpeg", 0.7);
      let imgName = new Date().valueOf() + ".jpg";
      let dataURLtoFile = this.dataURLtoFile(dataUrl, imgName);
      console.log(dataURLtoFile);
      // 开始人脸识别
      this.postFace(dataURLtoFile);
      this.imgbase64 = dataUrl.replace(/^data:image\/\w+;base64,/, "");
    },
    // 人脸验证
    postFace(dataURLtoFile) {
      const examId = this.$route.query.examinationId;
      let formData = new FormData();
      formData.append("file", dataURLtoFile);
      formData.append("examId", examId);
      const loading = this.$loading({
        lock: true,
        text: "正在上传认证",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$https
        .postWithFile(`/api/my/exam/faceVerify/${examId}`, formData)
        .then((res) => {
          loading.close();
          if (res.data) {
            this.$emit("success", res);
          } else {
            this.$emit("error");
            this.$message({
              type: "warning",
              duration: 30000,
              message: res.data.message || "认证失败,请单击《重新核验》",
            });
          }
        })
        .catch((error) => {
          this.$emit("error");
          loading.close();
          this.$message.error(error.debug);
        });
    },
    //将base64转换为文件
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    // 视频流启动
    getMediaStreamSuccess(stream) {
      window.stream = stream;
      this.video.srcObject = stream;
    },
    // 视频媒体流失败
    // eslint-disable-next-line no-unused-vars
    getMediaStreamError(error) {
      this.$message.error("获取摄像头失败，请确保摄像头已打开");
    },
  },
};
</script>

<style scoped>
.user-icon {
  position: relative;
  margin: 0 auto 10px;
  margin-top: 30px;
  height: 500px;
  display: flex;
  justify-content: center;
}
video,
canvas {
  position: absolute;
}
.videoSelectTypes {
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
}
.videoTitle {
  margin-top: 10px;
  text-align: center;
  font-size: 23px;
  color: rgb(15, 170, 85);
}
.videoTips {
  position: absolute;
  bottom: 0;
  font-size: 23px;
  color: rgb(15, 170, 85);
}
</style>
