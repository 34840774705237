<template>
  <div>
    <VideoCanvas
      :mediaConstraints="mediaConstraints"
      :key="count"
      @success="success"
      @error="error"
      @isNuclear="isNuclear"
    />
    <div class="tipsText">
      还剩下{{ frequency < 0 ? 0 : frequency }}次人脸核身次数
    </div>
    <div class="buttons">
      <el-button class="button" type="primary" @click="enter" v-if="isSuccess"
        >进入考场</el-button
      >
      <el-button
        class="button user-button"
        type="primary"
        @click="back"
        v-if="!isSuccess"
        >返回</el-button
      >
      <el-button
        class="button"
        type="primary"
        @click="reacquire"
        v-if="!isSuccess"
        :loading="isHttps"
        :disabled="reacquireDisabled"
        >重新核验</el-button
      >
      <!-- <div class="button" @click="enter" v-if="isSuccess">进入考场</div>
      <div class="button user-button" @click="back" v-if="!isSuccess">返回</div>
      <div class="button" @click="reacquire" v-if="!isSuccess">重新核验</div> -->
    </div>
  </div>
</template>

<script>
require("tracking/build/tracking-min.js");
require("tracking/build/data/face-min.js");
// 嘴巴等特征,后期可添加
// require('tracking/build/data/mouth-min.js')
// require('tracking/build/data/eye-min.js')
//var objects = new tracking.ObjectTracker(['face', 'eye', 'mouth']);
// require('tracking/examples/assets/stats.min.js')
import VideoCanvas from "./VideoCanvas.vue";
export default {
  name: "testTracking",
  props: ["mediaConstraints"],
  data() {
    return {
      // 记录拍照到了几次
      count: 0,
      isSuccess: false,
      frequency: 0,
      isHttps: false,
      reacquireDisabled: false,
    };
  },
  mounted() {
    console.log(45678);
    this.initData();
  },
  methods: {
    isNuclear(state) {
      this.reacquireDisabled = state;
    },
    initData(cb) {
      const examinationId = this.$route.query.examinationId;
      this.isHttps = true;
      this.$https
        .get("/api/my/exam/list")
        .then((res) => {
          this.isHttps = false;
          if (res.success) {
            const data = res.data;
            console.log(this.frequency);
            let frequency = Number(this.$route.query.verifyNum || 0);
            for (let el of data) {
              if (el.examinationId == examinationId) {
                frequency = el.verifyNum;
                break;
              }
            }
            this.frequency = frequency;
            if (cb) {
              cb();
            }
          }
        })
        .catch(() => {
          this.isHttps = false;
        });
    },
    reacquire() {
      if (!this.isHttps) {
        this.initData(() => {
          this.count++;
          this.isHttps = false;
        });
      }
    },
    enter() {
      this.$emit("success");
    },
    success(res) {
      this.frequency--;
      this.isSuccess = true;
      this.$message.success("认证成功");
    },
    error() {
      this.frequency--;
    },
    back() {
      this.$emit("onBack");
    },
  },
  components: {
    VideoCanvas,
  },
};
</script>

<style scoped>
.tipsText {
  text-align: center;
  font-size: 25px;
  color: red;
}
.buttons {
  display: flex;
}
.button {
  flex: 0 0 150px;
  /* height: 35px;
  line-height: 35px;
  background-color: skyblue;
  color: white;
  text-align: center;
  border-radius: 5px;
  font-size: 16px; */
  margin: 15px auto;
  cursor: pointer;
}
</style>
